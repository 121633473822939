// let BASE_URL = "http://18.132.63.105:3001/"; //new
let BASE_URL = "https://data.fiixit.co.uk/"; //new
// let BASE_URL = "http://3.10.5.183:3200/"; //new
// let BASE_URL = "http://localhost:3200/";
// let BASE_URL = "https://b714ca0a5f79.ngrok.io/";

let FETCHFIGFYURL = "https://pcls1.craftyclicks.co.uk/json/rapidaddress";
let FETCHFYTOKEN = "d2e8d-0992e-47a3e-0938a";

// const BUCKETNAME = "fiixitstaging";
const BUCKETNAME = "fiixitprod";
const REGION = "eu-west-2";

/**
 * //check the environment
 * if (process.env.REACT_APP_ENV === "development") {
 *     BASE_URL = "http://localhost:3200/";
 * }
 *
 * if (process.env.REACT_APP_ENV === "production") {
 *    BASE_URL = "http://68.183.245.203:3060/";
 * }
 */

export { BASE_URL, FETCHFIGFYURL, FETCHFYTOKEN, BUCKETNAME, REGION };
